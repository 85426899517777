import React from 'react';
import { Link, graphql } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';

const StyledTechsContainer = styled.main`
  max-width: 1000px;

  h1 {
    margin-bottom: 50px;
  }
  ul {
    color: ${props => props.theme.lightslate};

    li {
      font-size: var(--fz-xxl);

      a {
        color: ${props => props.theme.lightslate};

        .count {
          color: ${props => props.theme.slate};
          font-family: var(--font-mono);
          font-size: var(--fz-md);
        }
      }
    }
  }
`;

const TechsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
  location,
}) => (
  <Layout location={location}>
    <Helmet title="Techs" />

    <StyledTechsContainer>
      <span className="breadcrumb">
        <span className="arrow">&larr;</span>
        <Link to="/projects/archive/">All projects</Link>
      </span>

      <h1>Techs</h1>
      <ul className="fancy-list">
        {group.map(tech => (
          <li key={tech.fieldValue}>
            <Link to={`/projects/techs/${kebabCase(tech.fieldValue)}/`} className="inline-link">
              {tech.fieldValue} <span className="count">({tech.totalCount})</span>
            </Link>
          </li>
        ))}
      </ul>
    </StyledTechsContainer>
  </Layout>
);

TechsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired,
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
  location: PropTypes.object,
};

export default TechsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000, filter: { frontmatter: { draft: { ne: true } } }) {
      group(field: frontmatter___techs) {
        fieldValue
        totalCount
      }
    }
  }
`;
